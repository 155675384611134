import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Grid, Typography } from '@mui/material';
import WebAppzAPI from './../../API/WebAppzAPI';
import { useTranslation } from 'react-i18next';

const AddedBalance = ({ userData }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [rubles, setRubles] = useState('');
    const [tons, setTons] = useState('0');
    const [exchangeRate, setExchangeRate] = useState(1);
    const [placeholder, setPlaceholder] = useState('');
    const [isRu, setIsRu] = useState(t('welcome_message') === 'Добро пожаловать в наше приложение!' ? true : false);

    useEffect(() => {
        setIsRu(t('welcome_message') === 'Добро пожаловать в наше приложение!' ? true : false);
    }, [t]);

    useEffect(() => {
        const fetchExchangeRate = async () => {
            try {
                const rate = await WebAppzAPI.TonValueRub(); // Предполагается, что функция возвращает промис с курсом
                setExchangeRate(rate);
                // setPlaceholder(t('rubles_placeholder', { rate }));
                setPlaceholder("1 TON ~ " + rate + " RUB");
            } catch (error) {
                console.error('Ошибка при получении курса обмена:', error);
            }
        };

        // window.Telegram.WebApp.MainButton.hide();
        fetchExchangeRate();
    }, [t]);

    useEffect(() => {
        if(show) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.SecondaryButton.hide();
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.BackButton.onClick(() => {
                setShow(false);
            });
        } else {
            window.Telegram.WebApp.SecondaryButton.show();
            window.Telegram.WebApp.MainButton.show();
            window.Telegram.WebApp.BackButton.hide();
        }
    }, [show]);

    const handleRublesChange = (event) => {
        const rublesValue = event.target.value;
        setRubles(rublesValue);
        if (!isNaN(rublesValue) && rublesValue !== '') {
            setTons((rublesValue / exchangeRate).toFixed(2));
        } else {
            setTons('0');
        }
    };

    const handleOpen = () => setShow(true);
    const handleClose = () => setShow(false);

    const formatNumber = (num) => {
        if (num >= 1e6) {
            return (num / 1e6).toFixed(1) + ' млн';
        } else if (num >= 1e3) {
            return (num / 1e3).toFixed(1) + ' тыс';
        } else {
            return num.toString();
        }
    };

    const openInvoice = async(payment) => {
        var cost = rubles;

        const createPaymentRequest = await WebAppzAPI.createPay({
            'userid': userData.userid,
            'shopidpayment': 'connect',
            'type_transaction': "replenishment_balance_connect",
            'amount': cost,
            'currency': payment === 'CARD' ? "RUB" : "XTR"
        }, userData);

        if(payment === 'CARD') {
            if(createPaymentRequest.data.status === false) {
                window.Telegram.WebApp.showAlert(createPaymentRequest.data.message);
            } else {
                window.location.href = 'https://t.me/webappzconnectbot';
                window.Telegram.WebApp.close();
            }
        } else if(payment === 'STARS') {
            if(createPaymentRequest.data.invoice_link !== undefined) {
                const responsePayTG = await window.Telegram.WebApp.openInvoice(createPaymentRequest.data.invoice_link);
                if (responsePayTG === 'paid') {
                    window.Telegram.WebApp.showAlert(t('payment_successful'));
                } else if (responsePayTG === 'failed') {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('error');
                } else {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('warning');
                }
            } else {
                window.Telegram.WebApp.showAlert(t('start_payment_failed'));
            }
        }
    }

    return (
        <div>
            <div
                className={"text-center"}
                style={{ fontSize: 11, padding: "8 4", width: "max-content" }}
                onClick={handleOpen}
            >
                <span style={{width: "100%"}} className='d-flex justify-content-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28"><path fill="#fff" fillRule="evenodd" d="M3 14C3 7.925 7.925 3 14 3s11 4.925 11 11-4.925 11-11 11S3 20.075 3 14m11-5.75a.75.75 0 0 1 .75.75v4.25H19a.75.75 0 0 1 0 1.5h-4.25V19a.75.75 0 0 1-1.5 0v-4.25H9a.75.75 0 0 1 0-1.5h4.25V9a.75.75 0 0 1 .75-.75" clipRule="evenodd"></path></svg>
                </span>
                <b style={{color: "#fff"}}>{t('replenish_balance')}</b>
            </div>
            <Modal
                open={show}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    bgcolor: 'var(--tg-theme-secondary-bg-color)',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 2,
                    overflow: 'auto'
                }}>
                    <Box sx={{p: 2}}>
                        <Typography variant="h4" id="modal-title">
                            {t('replenish_balance')}
                        </Typography>
                    </Box>
                    
                    <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
                        <Grid item xs={5}>
                            <input
                                type="text"
                                placeholder={placeholder}
                                value={rubles}
                                onChange={handleRublesChange} className="ios-style-input" />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                sx={{
                                    minWidth: 0,
                                    backgroundColor: "var(--tg-theme-text-color)",
                                    borderRadius: "50%",
                                    aspectRatio: "1/1",
                                    p: 1
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--tg-theme-bg-color)"
                                    className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                        d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5"/>
                                </svg>
                            </Button>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                            <Box sx={{ width: 30, height: 30, mr: 2 }}>
                                <svg width="30" height="30" viewBox="0 0 56 56" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
                                        fill="#0098EA"></path>
                                    <path d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z"
                                        fill="white"></path>
                                </svg>
                            </Box>
                            {formatNumber(tons)}
                        </Grid>
                    </Grid>

                    <Box sx={{ p: 2 }}>
                        <Typography variant="h5">{t('payment_options')}</Typography>
                        <Button
                            className="ios-style-button w-100"
                            onClick={() => openInvoice("STARS")}
                        >
                            {t('pay_with_stars')}
                        </Button>
                        {isRu && (
                            <Button
                                sx={{mt: 2}}
                                className="ios-style-button d-flex justify-content-center w-100"
                                onClick={() => openInvoice('CARD')}
                            >
                                <svg style={{marginRight: "5px"}} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" className="bi bi-credit-card-2-front-fill mr-1" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"/>
                                </svg>
                                {t('pay_by_card')}
                            </Button>
                        )}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default AddedBalance;
