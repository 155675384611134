import React, { useEffect, useState } from "react";
import { Switch, FormControlLabel, CircularProgress, Box, ListItem, styled } from "@mui/material";
import WebAppzAPI from "./../../API/WebAppzAPI";
import FaceIDPng from './../../Assets/Pngs/faceid.png';
import { useTranslation } from "react-i18next";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 1,
        margin: 0,
        transitionDuration: "300ms",
        "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
            backgroundColor: theme.palette.mode === "dark" ? "#65C466" : "#65C466",
            opacity: 1,
            border: 0,
        },
        "& .MuiSwitch-thumb": {
            backgroundColor: "#fff",
            width: 24,
            height: 24,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
        },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 24,
        height: 24,
        backgroundColor: "#fff"
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "dark" ? "#E9E9EA" : "#E9E9EA",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
        duration: 500,
        }),
    },
}));

export default function Settings({ user, setOpenSettings, openSettings, setUser }) {
    const [biometricEnabled, setBiometricEnabled] = useState(user.BiometricConfirm === 1);
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        const backButton = window.Telegram.WebApp.BackButton;

        if(openSettings) {
            backButton.show();
            backButton.onClick(() => {
                setOpenSettings(false);
            })
        } else {
            backButton.hide();
        }

    }, [openSettings]);

  const {t} = useTranslation();

  const authenticateBiometric = async () => {
    try {
        const WebApp = window.Telegram.WebApp;
        const Telegram = window.Telegram;
  
      if (!WebApp.initData || !WebApp.initDataUnsafe) {
        console.log("WebApp не инициализирован.");
        return false;
      }

      const initBiometricManager = () => {
        const Telegram = window.Telegram;
        return new Promise((resolve, reject) => {
            if(Telegram.WebApp.BiometricManager.isInited === true) {
                resolve();
            } else {
                Telegram.WebApp.BiometricManager.init(() => {
                    if (Telegram.WebApp.BiometricManager.isInited) {
                        resolve();
                    } else {
                        console.log("Ошибка при инициализации BiometricManager");
                    }
                });
            }
        });
      };
  
      const requestBiometricAccess = () => {
        const Telegram = window.Telegram;
        return new Promise((resolve, reject) => {
          Telegram.WebApp.BiometricManager.requestAccess({}, (isAccessGranted) => {
            if (isAccessGranted) {
              resolve();
            } else {
              reject("Доступ к биометрии отклонен.");
            }
          });
        });
      };
  
      const authenticateUser = () => {
        const Telegram = window.Telegram;
        return new Promise((resolve, reject) => {
          Telegram.WebApp.BiometricManager.authenticate({}, (isAuthenticated, token) => {
            if (isAuthenticated) {
              resolve(true); // Успешная аутентификация
            } else {
              reject(false); // Неуспешная аутентификация
            }
          });
        });
      };
  
      const removeBiometricToken = () => {
        const Telegram = window.Telegram;
        return new Promise((resolve, reject) => {
          Telegram.WebApp.BiometricManager.updateBiometricToken('', (success) => {
            if (success) {
              resolve();
            } else {
              reject("Ошибка при удалении биометрического токена.");
            }
          });
        });
      };
  
      // Инициализация BiometricManager
      await initBiometricManager();
  
      // Проверка, сохранен ли биометрический токен
      if (Telegram.WebApp.BiometricManager.isBiometricTokenSaved) {
        return true; // Если токен сохранен, авторизация уже выполнена
      }
  
      // Запрос доступа к биометрии
      await requestBiometricAccess();
  
      // Аутентификация пользователя
      return await authenticateUser(); // Возвращаем результат аутентификации
  
    } catch (error) {
      console.log("Ошибка в authenticateBiometric:", error);
      return false; // Возвращаем false при ошибке
    }
  };

  const handleBiometricToggle = async (event) => {
    const newValue = event.target.checked;
    setLoading(true);

    const isAuthenticated = await authenticateBiometric();
    if (isAuthenticated) {
        const token = window.Telegram.WebApp.initData;
        const response = await WebAppzAPI.BiometricManager({
            userid: user.userid,
            enableBiometric: newValue === true ? 1 : 0,
            token: user.token,
            hash: token,
        });

        if (response.success) {
            setUser({
                ...user,
                BiometricConfirm: newValue === true ? 1 : 0
            })
            setBiometricEnabled(newValue);
        } else {
            console.error("Ошибка при изменении состояния биометрии.");
        }
    } else {
        console.error("Аутентификация не пройдена.");
    }

    setLoading(false);
  };

  return (
    <Box p={2} pt={0}>
        <h2>{t('settings')}</h2>
        <Box sx={{ backgroundColor: 'var(--tg-theme-bg-color)', paddingLeft: '12px', paddingRight: "12px", borderRadius: '12px', marginTop: '16px' }}>
            <ListItem disableGutters sx={{ 
                display: 'flex', 
                alignItems: 'center',
                padding: '8px 0'
            }}>
                <img style={{ marginRight: '0px', fontSize: '24px' }} src={FaceIDPng} width={24} height={24} />
                <div className="d-flex align-items-center" style={{ width: "100%", height: "100%", marginLeft: "8px", marginTop: "0px" }}>
                    {t('Face ID')}
                </div>
                <IOSSwitch
                    checked={biometricEnabled}
                    onChange={handleBiometricToggle}
                    color="primary"
                />
            </ListItem>
        </Box>
    </Box>
  );
}