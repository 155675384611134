import { Axios } from './axios';

const objectToFormData = (obj) => {
    const formData = new FormData();
    Object.keys(obj).forEach(key => formData.append(key, obj[key]));
    return formData;
};

const WebAppzAPI = {
    OAuthConnect: async ({ userData }) => {

        const formData = objectToFormData({
            ...userData,
            hash: window.Telegram.WebApp.initData
        });

        try {
            const { data } = await Axios.post('oauth/connect', formData);
            return data;
        } catch (err) {
            console.log(err);
            return { success: 0, message: 'Server Error!' };
        }
    },

    sendInviteToConnect: async ({ userData }) => {

        const sendData = {
            userid: userData.userid,
            token: userData.token
        }

        const formData = objectToFormData(userData);

        try {
            const { data } = await Axios.post('connect/sendInviteToConnect', formData);
            window.Telegram.WebApp.close();
            return data;
        } catch (err) {
            console.log(err);
            return { success: 0, message: 'Server Error!' };
        }
    },

    getStat: async ({ userData, shopid }) => {

        const sendData = {
            ...userData,
            shopidstat: shopid,
        }

        const formData = objectToFormData(sendData);

        try {
            const { data } = await Axios.post('connect/getStat', formData);
            return data.stat;
        } catch (err) {
            console.log(err);
            return { success: 0, message: 'Server Error!' };
        }
    },

    createPay: async(invoiceData, userData) => {
        const formData = objectToFormData({
            ...invoiceData,
            userid: userData.userid,
            token: userData.token,
        });

        const { data } = await Axios.post('payment/createPay', formData);

        return { success: 1, data: data };
    },

    changeLanguage: async(language_code, userData) => {
        const formData = objectToFormData({
            userid: userData.userid,
            token: userData.token,
            language_code: language_code
        });

        const { data } = await Axios.post('changeLanguage', formData);

        return { success: 1, data: data };
    },

    createShopRequest: async(data_send) => {
        const formData = objectToFormData(data_send);

        const { data } = await Axios.post('connect/createShop', formData);

        console.log(data);

        if(data.status === 'success') {
            // window.Telegram.WebApp.showAlert(data.message);
            window.location.href = 'https://t.me/webappzconnectbot';
            window.Telegram.WebApp.close();
        } else if(data.status === 'error') {
            window.Telegram.WebApp.showAlert(data.message);
        } else {
            window.Telegram.WebApp.showAlert("Server error");
        }

        return { success: 1, data: data };
    },

    BiometricManager: async(send_data) => {
        const formData = objectToFormData(send_data);

        const { data } = await Axios.post('connect/biometric-toggle', formData);

        return data;
    },

    createOrder: async (data) => {
        const formData = objectToFormData(data);
        const response = await Axios.post('createImageRequest', formData);
        return response.data;
    },

    sendImageGenBot: async (data) => {
        const formData = objectToFormData(data);
        const response = await Axios.post('sendImageGenBot', formData);
        return response.data;
    },

    TonValueRub: async (data) => {
        const response = await Axios.post('TonValueRub');
        return response.data;
    }
};

export default WebAppzAPI;