import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, CircularProgress, TextField, IconButton } from '@mui/material';
import WebAppzAPI from './../../API/WebAppzAPI';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';

const CustomButton = styled('div')({
    background: '#d31a22',
    height: 145,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer'
});

const GenerateImage = ({ user }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [error, setError] = useState('');
    const [history, setHistory] = useState(user.generate_images_history);

    useEffect(() => {

        // window.Telegram.WebApp.MainButton.hide();
        
        if (user.userid) {
            const ws = new WebSocket(`wss://a0yqw43aon9fqz7oj618yt3vfkalv0.aydawcux16rbjxf5ufl5c6y.ru:8088`);

            ws.onopen = () => {
                console.log('WebSocket Connected');
                ws.send(JSON.stringify({ userid: user.userid }));
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('WebSocket Message Received:', data);
                if (data.event === 'ImageStatusUpdated') {
                    console.log('Image Generated:', data.imageUrl);
                    setHistory((prevHistory) =>
                        prevHistory.map((item) =>
                            item.id === data.id ? { ...item, status: data.status, image: data.imageUrl } : item
                        )
                    );
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket Error: ', error);
            };

            ws.onclose = () => {
                console.log('WebSocket Disconnected');
            };

            return () => {
                ws.close();
            };
        }
    }, [user.userid]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        setLoading(true);
        setError('');

        try {
            const response = await WebAppzAPI.createOrder({ prompt, userid: user.userid, token: user.token });
            console.log('API Response:', response);

            if (response.status === false) {
                window.Telegram.WebApp.showAlert(response.message);
                setLoading(false);
                return;
            }

            setHistory((prevHistory) => [
                { id: response.id, prompt, status: 0 },
                ...prevHistory
            ]);

            setLoading(false);
            setPrompt('');
            setError(false);

        } catch (e) {
            console.error('Error creating order:', e);
            setError('Ошибка создания заказа');
            setLoading(false);
        }
    };

    const handleSendToBot = async (image) => {
        try {
            await WebAppzAPI.sendImageGenBot({ image });
            window.Telegram.WebApp.showAlert('Изображение отправлено в бот');
        } catch (e) {
            window.Telegram.WebApp.showAlert('Ошибка отправки изображения');
        }
    };

    React.useEffect(() => {
        if(open) {
            window.Telegram.WebApp.BackButton.hide();
        } else {
            window.Telegram.WebApp.BackButton.show();
        }
    }, [open]);

    return (
        <div>
            <Button onClick={handleOpen}>
                Генерация картинки
            </Button>

            <Modal open={open} onClose={handleClose} sx={{ overflowY: 'auto' }}>
                <Box sx={{ ...modalStyle }}>
                    <div className="w-100 d-flex">
                        <Typography variant="h4" component="h4">
                            Генерация картинки
                        </Typography>
                        <Button onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                 className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path
                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                            </svg>
                        </Button>
                    </div>
                    <textarea
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        placeholder="Что создать?"
                        rows={4}
                        style={{
                            width: '100%',
                            marginTop: '10px',
                            color: 'var(--tg-theme-text-color)',
                            background: 'var(--tg-theme-bg-color)',
                            border: '1px solid rgb(211, 26, 34)',
                            borderRadius: '4px',
                            padding: '8px',
                            boxSizing: 'border-box',
                            resize: 'vertical' // позволяет пользователю изменять размер textarea
                        }}
                    ></textarea>
                    <Button onClick={handleSubmit} variant="contained" color="primary" style={{marginTop: '10px'}}>
                        Отправить
                    </Button>
                    {loading && <CircularProgress style={{marginTop: '10px'}}/>}
                    {error && <Typography color="error" style={{marginTop: '10px'}}>{error}</Typography>}
                    <Typography variant="h5" style={{marginTop: '20px'}}>История генераций:</Typography>
                    <div className="row" style={{ marginTop: '20px' }}>
                        {history.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="col-12" style={{ position: 'relative', aspectRatio: "1/1", marginBottom: '20px' }}>
                                    {item.status === 0 ? (
                                        <div className="w-100 border border-secondary rounded" style={{
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <CircularProgress size={24} />
                                            <div className={"p-1"}
                                                 style={{ position: 'absolute', top: 8, right: 8 }}
                                                 onClick={() => window.Telegram.WebApp.showAlert("Запрос на создание картинки: " + item.prompt)}
                                                 color="primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     fill="rgb(211, 26, 34)" className="bi bi-card-text"
                                                     viewBox="0 0 16 16">
                                                    <path
                                                        d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
                                                    <path
                                                        d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8m0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"/>
                                                </svg>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <img src={item.image} className={"rounded"} alt="Generated"
                                                 style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                                            <Box className={"d-flex"} sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                background: "rgb(0,0,0,0) !important"
                                            }}>
                                                <div className={"p-1"}
                                                     onClick={() => window.Telegram.WebApp.showAlert("Запрос на создание картинки: " + item.prompt)}
                                                     color="primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                         fill="rgb(211, 26, 34)" className="bi bi-card-text"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
                                                        <path
                                                            d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8m0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"/>
                                                    </svg>
                                                </div>
                                                <div onClick={() => handleSendToBot(item.image)} className={"p-1"}
                                                     color="primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                         fill="rgb(211, 26, 34)" className="bi bi-download"
                                                         viewBox="0 0 16 16">
                                                    <path
                                                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                                        <path
                                                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                                    </svg>
                                                </div>
                                            </Box>
                                        </>
                                    )}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

const modalStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
};

export default GenerateImage;
