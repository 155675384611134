import React, { useRef } from 'react';
import { useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, ListItem, Box, Typography, IconButton } from '@mui/material';
import WebAppzAPI from './../../API/WebAppzAPI';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const parseDateString = (dateString) => {
    const dateParts = dateString.split(/[- :]/);
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5]);
};

// Функция для расчета разницы в днях
const calculateDaysDifference = (endPayDate) => {
    const currentDate = new Date();
    const endDate = parseDateString(endPayDate);

    const timeDifference = endDate - currentDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference > 0 ? daysDifference : -Math.abs(daysDifference);
};

const ShopCard = ({ handleOpenShops, userData, openShop, setOpenShop, shop, openModal, setOpenModal, isOpen = false }) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(openShop);
    const backButtonRef = useRef(null);
    const [stats, setStats] = React.useState({
        total_users: 0,
        users_today: 0,
        total_orders: 0,
        total_orders_month: 0,
        total_orders_today: 0,
        total_cost: 0,
        total_month_cost: 0,
        total_day_cost: 0,
        total_items: 0,
        items_day: 0,
        days_since_creation: ''
    });

    const handleOpen = () => {
        setOpen(true);
        setOpenShop(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenShop(false);
    };
    
    const handleBackButtonClick = useCallback((isOpen_) => {
        if (openShop || isOpen_) {
            setOpenShop(false);
            setOpen(false);
        } else if (openModal) {
            setOpenModal(false);
        }
    }, [openShop, openModal]);

    useEffect(() => {
        const backButton = window.Telegram.WebApp.BackButton;
        backButtonRef.current = backButton;
    
        if ((openShop || openModal) && !isOpen) {
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.SecondaryButton.hide();
            backButtonRef.current.show();
            backButtonRef.current.onClick(handleBackButtonClick);
        } else if((openShop || openModal) && isOpen) {
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.SecondaryButton.hide();
            backButtonRef.current.show();
            backButtonRef.current.onClick(() => handleBackButtonClick(true));
        } else {
            window.Telegram.WebApp.MainButton.show();
            window.Telegram.WebApp.SecondaryButton.show();
            backButtonRef.current.hide();
            backButtonRef.current.offClick(handleBackButtonClick);
        }
    
        return () => {
            backButtonRef.current.hide();
            backButtonRef.current.offClick(handleBackButtonClick);
        };
    }, [handleBackButtonClick, openShop, openModal, isOpen]);

    // if(window.Telegram.WebApp.initDataUnsafe.start_param !== undefined) {
    //     const params = window.Telegram.WebApp.initDataUnsafe.start_param.split('|');

    //     params.some(param => {
    //         const match = param.match(/shop(\d+)/);
    //         if (match) {
    //             userData.shopIDfind = match[1];
    //             setOpenModal(true);
    //         }
    //     });
    // }

    const openInvoice = async (payment) => {
        console.log(`Processing payment: ${payment}`);
        let cost = 0;

        if (payment === 'CARD') {
            cost = shop.type_add === 'auto' ? 5000 : 1000;

            const createPaymentRequest = await WebAppzAPI.createPay({
                'userid': userData.userid,
                'shopidpayment': shop.shopID,
                'type_transaction': "payment_shop",
                'amount': cost * 100,
                'currency': "RUB"
            }, userData);

            window.location.href = 'https://t.me/webappzconnectbot';
            window.Telegram.WebApp.close();

        } else if (payment === 'STARS') {
            cost = shop.type_add === 'auto' ? 4000 : 1500;

            const createPaymentRequest = await WebAppzAPI.createPay({
                'userid': userData.userid,
                'shopidpayment': shop.shopID,
                'type_transaction': "payment_shop",
                'amount': cost,
                'currency': "XTR"
            }, userData);

            if (createPaymentRequest.data.invoice_link !== undefined) {
                const responsePayTG = await window.Telegram.WebApp.openInvoice(createPaymentRequest.data.invoice_link);
                if (responsePayTG === 'paid') {
                    window.Telegram.WebApp.showAlert(t('payment_successful'));
                } else if (responsePayTG === 'failed') {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('error');
                } else {
                    window.Telegram.WebApp.HapticFeedback.notificationOccurred('warning');
                }
                console.log(responsePayTG);
            } else {
                window.Telegram.WebApp.showAlert(t("start_payment_failed"));
            }

            console.log(createPaymentRequest);
        }
    };

    React.useEffect(() => {
        const getStat = async () => {
            const statS = await WebAppzAPI.getStat({ userData: userData, shopid: shop.shopID });
            setStats(statS);
        };

        if (open) {
            getStat();
        } else {
            setStats({
                total_users: 0,
                users_today: 0,
                total_orders: 0,
                total_orders_month: 0,
                total_orders_today: 0,
                total_cost: 0,
                total_month_cost: 0,
                total_day_cost: 0,
                total_items: 0,
                items_day: 0,
                days_since_creation: ''
            });
        }
    }, [open]);

    return (
        <div className="col-12 p-1">
            {!isOpen && (
                <ListItem 
                    disableGutters 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        padding: '8px',
                        backgroundColor: 'var(--tg-theme-bg-color)', // Фон, соответствующий Telegram WebApp
                        borderRadius: '12px', 
                        marginBottom: '2px',
                    }}
                    onClick={handleOpen}
                >
                    {/* Изображение магазина */}
                    <Box sx={{ flex: '0 0 40px', marginRight: '16px' }}>
                        <img 
                            src={shop.image} 
                            width="100%" 
                            className="rounded" 
                            alt={shop.bot_tg_username} 
                            style={{ 
                                borderRadius: '12px', 
                            }} 
                        />
                    </Box>
                    
                    {/* Информация о магазине */}
                    <Box sx={{ flex: '1' }}>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', margin: 0 }}>
                            {shop.bot_tg_username} {userData.userid.toString() === '561291839' || userData.userid.toString() === '169355528' ? (`#${shop.shopID}`) : null}
                        </p>
            
                        {/* Если пользователь админ, показываем информацию о дате */}
                        {userData.userid.toString() === '561291839' || userData.userid.toString() === '169355528' ? (
                            <Box>
                                {shop.end_pay_date !== null && (
                                    <>
                                        {/* Дата окончания подписки */}
                                        <p style={{ fontSize: '14px', color: 'var(--tg-theme-text-secondary-color)', margin: 0 }}>
                                            {t(calculateDaysDifference(shop.end_pay_date) <= 0 ? 'subscription_expired' : 'subscription_expires', { 
                                                date: format(new Date(shop.end_pay_date.slice(0, -9)), 'd MMMM yyyy', { locale: ru }) 
                                            })}
                                        </p>
                                        
                                        {/* Статус подписки */}
                                        <p style={{ fontSize: '14px', margin: 0, color: calculateDaysDifference(shop.end_pay_date) <= 0 ? 'red' : 'green' }}>
                                            {t(calculateDaysDifference(shop.end_pay_date) <= 0 ? 'shop_overdue' : 'shop_paid_for', { 
                                                days: calculateDaysDifference(shop.end_pay_date)
                                            })}
                                        </p>
                                    </>
                                )}
                            </Box>
                        ) : null}
                    </Box>
            
                    <IconButton>
                        <ChevronRightIcon />
                    </IconButton>
                </ListItem>
            )}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{ background: "var(--tg-theme-secondary-bg-color)" }}
            >
                <DialogTitle sx={{ background: "var(--tg-theme-secondary-bg-color)" }} id="responsive-dialog-title">
                    <a href={`https://t.me/@${shop.bot_tg_username}`}>@{shop.bot_tg_username}</a>
                </DialogTitle>
                <DialogContent sx={{ background: "var(--tg-theme-secondary-bg-color)" }}>
                    <img src={shop.image} alt={shop.bot_tg_username} className="rounded"
                         style={{width: '100%', marginTop: '10px',borderRadius: "12px" }}/>
                    <DialogContentText sx={{mt: 2}}></DialogContentText>
                    <Button className={"w-100 ios-style-button"} onClick={() => window.location.href = shop.webapp_link}>{t('open_catalog')}</Button>
                    {shop.end_pay_date !== null && (
                        <>
                            <h5 className={"mt-2"}>{t('payment_shop')}</h5>
                            <p>{t(calculateDaysDifference(shop.end_pay_date) <= 0 ? 'subscription_expired' : 'subscription_expires', { date: format(new Date(shop.end_pay_date.slice(0, -9)), 'd MMMM yyyy', {locale: ru}) })}</p>
                            <p className={`text-${calculateDaysDifference(shop.end_pay_date) <= 0 ? "danger" : "success"}`}>
                                {t(calculateDaysDifference(shop.end_pay_date) <= 0 ? 'shop_overdue' : 'shop_paid_for', { days: calculateDaysDifference(shop.end_pay_date) >= 0 ? calculateDaysDifference(shop.end_pay_date) : '0' })}
                            </p>
                            <Button onClick={() => openInvoice("STARS")}
                                    className={"ios-style-button mt-2 w-100 d-flex justify-content-center align-items-center"}>
                                {t('pay_with_stars')}
                            </Button>
                            {
                                shop.payment_card_access == 1 && (
                                    <Button style={{marginTop: "10px"}} onClick={() => openInvoice('CARD')}
                                            className={"ios-style-button mt-2 w-100 d-flex justify-content-center align-items-center"}>
                                        <svg style={{marginRight: "5px"}} xmlns="http://www.w3.org/2000/svg" width="25"
                                             height="25" fill="#fff"
                                             className="bi bi-credit-card-2-front-fill mr-1"
                                             viewBox="0 0 16 16">
                                            <path
                                                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"/>
                                        </svg>
                                        {t('pay_by_card')}
                                    </Button>
                                )
                            }
                        </>
                    )}
                    {stats.total_users !== undefined && (
                        <div>
                            <p>{t('shop_exists_for_days', { days: stats.days_since_creation })}</p>
                            <p>{t('total_users', { count: stats.total_users.toLocaleString() })}</p>
                            <p>{t('new_users_today', { count: stats.users_today.toLocaleString() })}</p>
                            <p>{t('total_orders', { count: stats.total_orders.toLocaleString() })}</p>
                            <p>{t('monthly_orders', { count: stats.total_orders_month.toLocaleString() })}</p>
                            <p>{t('today_orders', { count: stats.total_orders_today.toLocaleString() })}</p>
                            <p>{t('total_cost', { amount: stats.total_cost.toLocaleString() })}</p>
                            <p>{t('monthly_cost', { amount: stats.total_month_cost.toLocaleString() })}</p>
                            <p>{t('today_cost', { amount: stats.total_day_cost.toLocaleString() })}</p>
                            <p>{t('total_items', { count: stats.total_items.toLocaleString() })}</p>
                            <p>{t('items_added_today', { count: stats.items_day.toLocaleString() })}</p>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ShopCard;
