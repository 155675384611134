import React from 'react';

import WebAppzLogo from './../../Assets/Pngs/webappz_logo.jpg';

export default function LoadingBlock() {
  return (
    <div style={{width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <img src={WebAppzLogo} alt="Logo" style={{width: "100px", height: "100px"}} />
    </div>
  )
}
