import React from 'react';
import { useTranslation } from 'react-i18next';
import Flag from 'react-flagkit';

import WebAppzAPI from './../../API/WebAppzAPI';

import { Box, ListItem, Dialog, DialogTitle, DialogContent, Divider } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';

import LanguageIcon from './../../Assets/Svg/language_icon';

const languages = [
    { code: 'en', name: 'English', country: 'GB' },
    { code: 'ru', name: 'Русский', country: 'RU' },
    { code: 'de', name: 'Deutsch', country: 'DE' },
    { code: 'fr', name: 'Français', country: 'FR' },
    { code: 'ar', name: 'العربية', country: 'AR' },
    { code: 'es', name: 'Español', country: 'ES' },
    { code: 'pt', name: 'Português', country: 'PT' },
    { code: 'it', name: 'Italiano', country: 'IT' },
    { code: 'pl', name: 'Polski', country: 'PL' },
    { code: 'uk', name: 'Українська', country: 'UA' },
    { code: 'zh', name: '中文', country: 'CN' },
    { code: 'vi', name: 'Tiếng Việt', country: 'VN' },
    { code: 'fa', name: 'فارسی', country: 'IR' },
    { code: 'he', name: 'עברית', country: 'IL' },
];

const LanguageButton = ({ selectedLanguage }) => {
    const language = languages.find(lang => lang.code === selectedLanguage);
    return (
        <div className="d-flex align-items-center" style={{marginRight: "10px", color: "var(--tg-theme-subtitle-text-color)"}}>
            <Flag country={language.country} />
            <small style={{ marginLeft: 8 }}>{language.name}</small>
        </div>
    );
};

const LanguageSelector = ({ selectedLanguage, handleLanguageChange }) => {
    return (
      <Box
        sx={{
            width: "calc(100% - 40px)",
            backgroundColor: 'var(--tg-theme-bg-color)',
            paddingLeft: '3px',
            paddingRight: '12px',
            borderRadius: '12px',
            marginTop: '8px',
        }}
      >
        {languages.map((language, index) => (
          <React.Fragment key={language.code}>
            <ListItem
              disableGutters
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 0',
                cursor: 'pointer',
              }}
              onClick={() => handleLanguageChange(language.code)}
            >
              <Flag
                country={language.country}
                style={{ marginRight: '16px', width: '24px', height: '24px' }}
              />
              <div
                className="d-flex align-items-center"
                style={{
                  width: '100%',
                  height: '100%',
                  marginLeft: '8px',
                  marginTop: '2px',
                }}
              >
                {language.name}
              </div>
              {selectedLanguage === language.code && (
                <CheckIcon sx={{ color: '#007AFF' }} />
              )}
            </ListItem>
            {index < languages.length - 1 && (
              <Divider sx={{ marginLeft: '32px' }} />
            )}
          </React.Fragment>
        ))}
      </Box>
    );
  };

export default function SelectLanguage({ user }) {
    const { t, i18n } = useTranslation();
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = React.useState(user.language_code);

    const handleLanguageChange = async (newLanguage) => {
        setSelectedLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        window.Telegram.WebApp.MainButton.text = t('invite_button');
        window.Telegram.WebApp.SecondaryButton.text = t('create_shop');
        setOpenModal(false);
        await WebAppzAPI.changeLanguage(newLanguage, user);
    };

    React.useEffect(() => {
        const backButton = window.Telegram.WebApp.BackButton;
        const mainButton = window.Telegram.WebApp.MainButton;
        const secondaryButton = window.Telegram.WebApp.SecondaryButton;

        if(openModal) {
            backButton.show();
            backButton.onClick(() => {
                setOpenModal(false);
            });
            mainButton.hide();
            secondaryButton.hide()
        } else {
            backButton.hide();
            mainButton.show();
            secondaryButton.show();
        }

        return () => {
            backButton.offClick();
        };
    }, [openModal]);

    return (
        <Box sx={{ backgroundColor: 'var(--tg-theme-bg-color)', paddingLeft: '12px', paddingRight: "12px", borderRadius: '12px', marginTop: '16px' }}>
            <ListItem 
                onClick={() => setOpenModal(true)}
                disableGutters 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    padding: '8px 0',
                    cursor: 'pointer',
                }}>
                
                <LanguageIcon sx={{ marginRight: '16px', fontSize: '24px' }} />
                
                <div className="d-flex align-items-center" style={{ width: "100%", height: "100%", marginLeft: "8px", marginTop: "2px" }}>
                    {t('select_language')}
                </div>

                <LanguageButton selectedLanguage={selectedLanguage} />
                
            </ListItem>

            <Dialog 
                fullScreen
                open={openModal} 
                onClose={() => setOpenModal(false)}
                PaperProps={{
                    style: {
                        backgroundColor: 'var(--tg-theme-bg-color)',
                        color: 'var(--tg-theme-text-color)',
                    }
                }}
            >
                <DialogTitle>{t('select_language')}</DialogTitle>
                <DialogContent style={{ minWidth: "100%", height: "100%" }}>
                    <LanguageSelector selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} />
                </DialogContent>
            </Dialog>
        </Box>
    );
}
