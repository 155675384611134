import React, { useEffect, useState } from 'react';
import WelcomeHeader from './Components/Header/WelcomeHeader';
import WebAppzAPI from './API/WebAppzAPI';
import WelcomeBlock from './Components/Blocks/WelcomeBlock';
import ShopsRefsContainer from './Components/Blocks/ShopsRefsContainer';
import WebAppzChat from './Components/Chat/WebAppzChat';
import ShopCard from './Components/Cards/ShopCard';
import LoadingBlock from './Components/Blocks/LoadingBlock';

import Settings from './Comps/Control/Settings';

import WelcomePage from './Pages/WelcomePage';

import { useTranslation } from 'react-i18next';
import './i18n';

import './Assets/Css/style.css';

export default () => {
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState(null);
    const [shops, setShops] = useState(null);
    const [refs, setRefs] = useState(null);
    const [isFetched, setIsFetched] = useState(false);
    const [page, setPage] = useState('welcome');

    window.Telegram.WebApp.expand();

    const authenticateBiometric = async () => {
        try {
            const WebApp = window.Telegram.WebApp;
    
            // Превращаем инициализацию в промис
            const initBiometricManager = () => {
                return new Promise((resolve, reject) => {
                    window.Telegram.WebApp.BiometricManager.init(() => {
                        if (window.Telegram.WebApp.BiometricManager.isInited) {
                            resolve();
                        } else {
                            reject(false);  // Возвращаем false, если не удалось инициализировать
                        }
                    });
                });
            };
    
            // Превращаем запрос доступа в промис
            const requestBiometricAccess = () => {
                return new Promise((resolve, reject) => {
                    window.Telegram.WebApp.BiometricManager.requestAccess({}, (isAccessGranted) => {
                        if (isAccessGranted) {
                            resolve();
                        } else {
                            reject(false);  // Возвращаем false, если доступ отклонен
                        }
                    });
                });
            };
    
            // Превращаем аутентификацию в промис
            const authenticateUser = () => {
                return new Promise((resolve, reject) => {
                    window.Telegram.WebApp.BiometricManager.authenticate({}, (isAuthenticated) => {
                        if (isAuthenticated) {
                            resolve(true);  // Возвращаем true, если аутентификация успешна
                        } else {
                            reject(false);  // Возвращаем false, если аутентификация не удалась
                        }
                    });
                });
            };
    
            // Выполняем последовательно инициализацию, запрос доступа и аутентификацию
            await initBiometricManager();
            await requestBiometricAccess();
            const result = await authenticateUser();
    
            return result;
    
        } catch (error) {
            console.error("Ошибка биометрической аутентификации:", error);
            return false;  // Возвращаем false, если произошла ошибка на любом этапе
        }
    }

    const [openModalShops, setOpenModalShops] = useState(false);

    useEffect(() => {
        if (!isFetched) {
            const fetchData = async () => {
                const WebApp = window.Telegram.WebApp;

                if (typeof (WebApp.initDataUnsafe.user) !== 'undefined') {
                    let userData = {
                        userid: `${WebApp.initDataUnsafe.user.id}`,
                        first_name: `${WebApp.initDataUnsafe.user.first_name}`,
                        last_name: `${WebApp.initDataUnsafe.user.last_name}`,
                        username: `${WebApp.initDataUnsafe.user.username === undefined ? null : WebApp.initDataUnsafe.user.username}`,
                        refID: '0',
                    };

                    if(window.Telegram.WebApp.initDataUnsafe.start_param !== undefined) {
                        const params = window.Telegram.WebApp.initDataUnsafe.start_param.split('|');
                        params.some(param => {
                            const match = param.match(/ref(\d+)/);
                            if (match) {
                                userData.refID = match[1];
                                return true;
                            }
                            return false;
                        });

                        params.some(param => {
                            const match = param.match(/shop(\d+)/);
                            if (match) {
                                userData.shopIDfind = match[1];
                                setOpenModalShops(true);
                                return true;
                            }
                            return false;
                        });

                        console.log(params)
                    }

                    var connectData = await WebAppzAPI.OAuthConnect({ userData });
                    userData.userPhoto = connectData.userPhoto;
                    userData.balance = connectData.balance;
                    userData.token = connectData.token;
                    userData.generate_images_history = connectData.generate_images_history;
                    userData.firstLevelRefs = connectData.firstLevelRefs;
                    userData.secondLevelRefs = connectData.secondLevelRefs;
                    userData.BiometricConfirm = connectData.BiometricConfirm;
                    if (userData.BiometricConfirm === 1) {
                        const authBiometricAnswere = await authenticateBiometric();
                        userData.authBiometricAnswere = authBiometricAnswere;
                    }
                    setUser(userData);
                    if(connectData.shop_answer !== null && connectData.shop_answer !== undefined) {
                        userData.shop_answer = connectData.shop_answer;
                    }
                    setRefs({
                        'firstLevel': connectData.firstLevelRefs,
                        'secondLevel': connectData.secondLevelRefs,
                    });
                    setShops(connectData.shops);

                    if(connectData.language_code === null || connectData.language_code === undefined) {
                        i18n.changeLanguage(window.Telegram.WebApp.initDataUnsafe.user.language_code);
                        WebAppzAPI.changeLanguage(window.Telegram.WebApp.initDataUnsafe.user.language_code, userData);
                        userData.language_code = window.Telegram.WebApp.initDataUnsafe.user.language_code;
                    } else {
                        i18n.changeLanguage(connectData.language_code);
                        userData.language_code = connectData.language_code;
                    }

                    console.log(connectData);

                    window.Telegram.WebApp.MainButton.text = t('invite_button');

                } else {
                    window.location.href = '/block';
                }
            };

            fetchData();
            setIsFetched(true);
        }
    }, [isFetched, setIsFetched]);

    React.useEffect(() => {
        if(user !== null) {
            if(page === 'welcome') {
            
            } else if(page === 'webappz_chat') {
                window.Telegram.WebApp.BackButton.show();
                window.Telegram.WebApp.BackButton.onClick(() => {
                    setPage('welcome');
                });
            }
    
            return () => {
                window.Telegram.WebApp.MainButton.offClick();
                window.Telegram.WebApp.BackButton.offClick();
            };
        }
    }, [page, user]);

    const sendDataToServer = async (data) => {
        data.userid = user.userid;
        data.token = user.token;
        console.log(data);
        const response = await WebAppzAPI.createShopRequest(data);
    };

    React.useEffect(() => {
        if(user !== null && (user.authBiometricAnswere === undefined || user.authBiometricAnswere === true)) {
            window.Telegram.WebApp.SecondaryButton.text = t('invite_button');
            window.Telegram.WebApp.SecondaryButton.color = '#0098EA';
            window.Telegram.WebApp.SecondaryButton.textColor = '#fff';
            window.Telegram.WebApp.SecondaryButton.show();
            window.Telegram.WebApp.SecondaryButton.onClick(() => {
                window.location.href="https://t.me/share/url?url=https://t.me/webappzconnectbot/app?startapp=ref" + user.userid + "&text=" + t('connect_link');
            });
            window.Telegram.WebApp.MainButton.show();
            window.Telegram.WebApp.MainButton.text = t('create_shop');
            window.Telegram.WebApp.MainButton.color = '#0098EA';
            window.Telegram.WebApp.MainButton.onClick(() => {
                window.Telegram.WebApp.showPopup({
                    // title: "Вопрос",
                    message: t('do_you_have_a_telegram_channel'),
                    buttons: [
                        { id: "yes", type: "default", text: t('yes') },
                        { id: "no", type: "default", text: t('no') }
                    ],
                }, (buttonId) => {
                    if (buttonId === "yes") {
                    // Шаг 2: Если канал есть, запрашиваем ссылку на канал
                    window.Telegram.WebApp.showPopup({
                        title: t('enter_channel_link'),
                        message: t('input_link_channel'),
                        buttons: [{ id: "max_24_symbolssubmit", type: "ok", text: "Отправить" }],
                    }, async () => {
                        const channelLink = prompt(t("linkusername_to_channel"));

                        if(channelLink === null || channelLink.length < 1) {
                            alert(t('incorrect_channel_link'));
                        } else {
                            if (channelLink !== null && (channelLink.startsWith("https://") || channelLink.startsWith("@"))) {
                                const data = { text: channelLink, isChannel: true };
                                await sendDataToServer(data);
                            } else {
                                alert(t('incorrect_channel_link'));
                            }
                        }
                    });
                    } else if (buttonId === "no") {
                        const shopName = prompt(t('notify_max_24_symbols'));
                    
                        if(shopName !== null) {
                            if (shopName.length <= 24 && shopName.length > 4) {
                                const data = { text: shopName, isChannel: false };
                                sendDataToServer(data);
                            } else {
                                alert(t('max_24_symbols'));
                            }
                        }
                    }
                });
            });

            // WebAppzAPI.createShopRequest(data)
        } else {
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.SecondaryButton.hide();
        }
    }, [t]);

    const [openSettings, setOpenSettings] = useState(false);

    window.Telegram.WebApp.SettingsButton.show();
    window.Telegram.WebApp.SettingsButton.onClick(() => {
        if(openSettings) {
            setOpenSettings(false);
        } else {
            setOpenSettings(true);
        }
    });

    // if(user === null || user.userid !== '561291839') {
    //     return <LoadingBlock />
    // }

    if(user !== null && user.authBiometricAnswere !== undefined && user.authBiometricAnswere === false) {
        window.Telegram.WebApp.MainButton.hide();
        window.Telegram.WebApp.SecondaryButton.hide();
        return <LoadingBlock message={"Failed to verify biometry"} />;
    }

    React.useEffect(() => {
        if(openSettings) {
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.SecondaryButton.hide();
            // return <Settings openSettings={openSettings} setUser={setUser} setOpenSettings={setOpenSettings} user={user} shops={shops} refs={refs} />
        } else {
            window.Telegram.WebApp.MainButton.show();
            window.Telegram.WebApp.SecondaryButton.show();
        }
    }, [openSettings])

    return (
        <>
            {user ? (
                <>
                    {
                        openSettings && (
                            <Settings openSettings={openSettings} setUser={setUser} setOpenSettings={setOpenSettings} user={user} shops={shops} refs={refs} />
                        )
                    }
                    {
                        page === 'welcome' && !openSettings ? (
                            <div className="App">
                                
                                <WelcomePage user={user} openModalShops={openModalShops} setOpenModalShops={setOpenModalShops} refs={refs} shops={shops} />
                                {
                                    user.shop_answer !== undefined && (
                                        <ShopCard userData={user} isOpen={true} openModal={openModalShops} openShop={true} setOpenModal={setOpenModalShops} shop={user.shop_answer} setOpenShop={() => {}} />
                                    )
                                }
                            </div>
                        ) : null
                    }
                </>
            ) : null}
        </>
    );
}