import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FaPlusCircle, FaBars, FaEdit, FaImage, FaKeyboard } from 'react-icons/fa';
import ChatActions from './ChatActions';
import { Drawer, List, ListItem, ListItemText, IconButton, Button, InputBase, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'Arial', sans-serif;
    background-color: var(--tg-theme-bg-secondary-color);
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--tg-theme-bg-color);
`;

const ChatTitle = styled.div`
    font-size: 18px;
`;

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background: var(--tg-theme-bg-color);
    border-radius: 10px;
    overflow: hidden;
`;

const MessagesContainer = styled.div`
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    background: var(--tg-theme-bg-secondary-color);
`;

const Message = styled.div`
    margin: 5px 0;
    padding: 10px;
    background: ${(props) => (props.isUser ? 'var(--tg-theme-secondary-bg-color)' : 'var(--tg-theme-secondary-bg-color)')};
    align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
    border-radius: 10px;
    max-width: 60%;
    word-wrap: break-word;
`;

const GPTMessage = styled.div`
    margin: 5px 0;
    padding: 10px;
    align-self: flex-start;
    border-radius: 10px;
    max-width: 60%;
    word-wrap: break-word;
    white-space: pre-wrap;
`;

const CodeBlock = styled.pre`
    background: #333;
    color: #f8f8f2;
    padding: 10px;
    border-radius: 0 0 5px 5px;
    overflow-x: auto;
`;

const CodeLanguage = styled.div`
    background: #555;
    color: #f8f8f2;
    padding: 5px;
    border-radius: 5px 5px 0 0;
    font-size: 12px;
`;

const ImageMessage = styled.img`
    max-width: 80%;
    border-radius: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    padding: 10px;
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color) !important;
`;

const CustomInput = styled(InputBase)`
    flex: 1;
    padding: 4px 8px;
    font-size: 18px;
    font-weight: 400;
    border: 2px solid var(--tg-theme-text-color);
    color: var(--tg-theme-text-color) !important;
    border-radius: 4px;
    outline: none;
    margin-right: 10px;
`;

const renderMessage = (message, userData) => {
    const isUser = message.userid === userData.userid;

    if (message.answer === undefined || message.answer === null) {
        return;
    }

    const parts = message.answer.split('```');

    return (
        <div key={message.id}>
            <div className="w-100 d-flex justify-content-end">
                <Message isUser={isUser}>
                    {message.message}
                </Message>
            </div>
            {
                message.answer === '!loading' ? (
                    <CircularProgress/>
                ) : message.answer === '!loading-image' ? (
                    <div className={"d-flex align-items-center justify-content-center border rounded"} style={{width: "70%", height: "auto", aspectRatio: "1/1", border: "1px solid var(--tg-theme-secondary-bg-color)"}}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <>
                        {message.image ? (
                            <>
                                <ImageMessage src={message.image} alt="GPT Response"/>
                                <br/>
                                <Button sx={{minWidth: 0, aspectRatio: "1/1"}} className={"mt-2"}
                                        onClick={() => window.location.href = "https://t.me/share/url?url=" + message.image + "&text=Изображение созданно webappz AI https://t.me/webappzconnectbot/app?startapp=ref" + userData.userid}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                         className="bi bi-link-45deg" viewBox="0 0 16 16">
                                        <path
                                            d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z"/>
                                        <path
                                            d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"/>
                                    </svg>
                                </Button>
                            </>
                        ) : (
                            <GPTMessage style={message.answer.length > 40 ? {maxWidth: "90%"} : {maxWidth: "60%"}}>
                                {parts.map((part, index) => (
                                    index % 2 === 1 ? (
                                        <div key={index}>
                                            <CodeLanguage>{part.split('\n')[0]}</CodeLanguage>
                                            <CodeBlock>
                                                {part.split('\n').slice(1).join('\n')}
                                            </CodeBlock>
                                        </div>
                                    ) : (
                                        <React.Fragment key={index}>
                                            {part}
                                        </React.Fragment>
                                    )
                                ))}
                                <br />
                                <Button sx={{ minWidth: 0, aspectRatio: "1/1" }} className={"mt-2"} onClick={() => window.location.href = "https://t.me/share/url?url=https://t.me/webappzconnectbot/app?startapp=ref" + userData.userid + "&text=" + message.answer}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-link-45deg" viewBox="0 0 16 16">
                                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z" />
                                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z" />
                                    </svg>
                                </Button>
                            </GPTMessage>
                        )}
                    </>
                )
            }
        </div>
    );
};

const App = ({ userData }) => {
    const { t } = useTranslation();
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [page, setPage] = useState(1);
    const [chats, setChats] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [newChatName, setNewChatName] = useState('');
    const [inputType, setInputType] = useState('text'); // 'text' or 'image'
    const messagesContainerRef = useRef(null);

    const fetchChatHistory = useCallback(async (chatId, page) => {
        const response = await ChatActions.getChatHistory({ chatId, userId: userData.userid, page });
        if (response.success !== 0) {
            setMessages((prevMessages) => [...prevMessages, ...response]);
        } else {
            console.error(response.message);
        }
    }, [userData.userid]);

    useEffect(() => {
        window.Telegram.WebApp.MainButton.hide();
        // Fetch initial chats list
        const fetchChats = async () => {
            const response = await ChatActions.getChats({ userId: userData.userid });
            if (response.status) {
                setChats(response.chats);
                if (response.chats.length > 0) {
                    setCurrentChat(response.chats[0].id);
                } else {
                    handleCreateChat();
                }
            } else {
                console.error(response.message);
            }
        };
        fetchChats();
    }, [userData.userid]);

    useEffect(() => {
        if (currentChat) {
            setMessages([]);
            setPage(1);
            fetchChatHistory(currentChat, 1);
        }
    }, [currentChat, fetchChatHistory]);

    const handleSendMessage = async () => {
        if (input.trim() && currentChat && !sendingMessage) {
            setSendingMessage(true);
            const tempId = new Date().getTime();
            const newMessage = {
                id: tempId,
                userid: userData.userid,
                message: input,
                answer: inputType === 'text' ? '!loading' : '!loading-image',
            };
            setMessages((prevMessages) => [newMessage, ...prevMessages]);
            setInput('');

            const response = await ChatActions.sendMessage({ chatId: currentChat, userId: userData.userid, message: input, type: inputType });
            if (response.status === true) {
                setMessages((prevMessages) => {
                    return prevMessages.map(msg => msg.id === tempId ? { ...msg, answer: response.output, id: response.message_id, image: response.image } : msg);
                });
            } else {
                window.Telegram.WebApp.showAlert(t('error_occurred'));
                setMessages((prevMessages) => {
                    return prevMessages.map(msg => msg.id === tempId ? {} : msg);
                });
            }
            setSendingMessage(false);
        }
    };

    const handleScroll = () => {
        // if (messagesContainerRef.current.scrollTop === 0 && !loading) {
        //     setLoading(true);
        //     setPage((prevPage) => prevPage + 1);
        //     fetchChatHistory(currentChat, page + 1).then(() => {
        //         setLoading(false);
        //     });
        // }
    };

    const handleCreateChat = async () => {
        const newChat = await ChatActions.createChat({ name: t('chat_name'), userId: userData.userid });
        if (newChat.success !== 0) {
            setChats({
                ...chats,
                newChat
            });
            setCurrentChat(newChat.id);
            window.Telegram.WebApp.showAlert(t('chat_created'));
        } else {
            window.Telegram.WebApp.showAlert(t('error_occurred'));
        }
    };

    const handleEditChatName = async () => {
        const response = await ChatActions.editChatName({ chatId: currentChat, userId: userData.userid, newName: newChatName });

        if (response.status) {
            setChats((prevChats) => prevChats.map(chat => chat.id === currentChat ? { ...chat, name: newChatName } : chat));
            setEditDialogOpen(false);
            setNewChatName('');
        } else {
            window.Telegram.WebApp.showAlert(t('error_occurred'));
        }
    };

    const deleteChat = async(chatId) => {
        const response = await ChatActions.deleteChat({ chatId: currentChat, userId: userData.userid });

        if (response.status) {
            const fetchChats = async () => {
                const response = await ChatActions.getChats({ userId: userData.userid });
                if (response.status) {
                    setChats(response.chats);
                    if (response.chats.length > 0) {
                        setCurrentChat(response.chats[0].id);
                    } else {
                        setCurrentChat(null);
                        setMessages([]);
                    }
                    window.Telegram.WebApp.showAlert(t('chat_deleted'));
                } else {
                    window.Telegram.WebApp.showAlert(t('error_occurred'));
                }
            };
            fetchChats();
        } else {
            window.Telegram.WebApp.showAlert(t('error_occurred'));
        }
    }

    return (
        <AppContainer>
            <Header>
                <ChatTitle>{currentChat && Object.values(chats).length > 0 ? Object.values(chats).find(chat => chat.id === currentChat).name : t('choose_or_create_chat')}</ChatTitle>
                <Button onClick={() => setDrawerOpen(true)}>
                    <FaBars size={25} />
                </Button>
            </Header>
            <Drawer anchor="left" style={{ minWidth: "50%" }} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <div className="w-100 mt-2 ml-2">
                    <Button onClick={handleCreateChat}>
                        <FaPlusCircle size={25} />
                    </Button>
                </div>
                <List>
                    {Object.values(chats).map((chat) => (
                        <ListItem button key={chat.id} onClick={() => {
                            setCurrentChat(chat.id);
                            setDrawerOpen(false);
                        }} selected={currentChat === chat.id}>
                            <ListItemText primary={chat.name} />
                            <Button onClick={() => {
                                setNewChatName(chat.name);
                                setEditDialogOpen(true);
                            }} sx={{ minWidth: 0 }}>
                                <FaEdit size={16} />
                            </Button>
                            <Button onClick={() => {
                                deleteChat(chat.id);
                            }} sx={{ minWidth: 0 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-trash3" viewBox="0 0 16 16">
                                    <path
                                        d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                </svg>
                            </Button>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <ChatContainer>
                <MessagesContainer ref={messagesContainerRef} onScroll={handleScroll}>
                    {messages.map((message) => renderMessage(message, userData))}
                    {loading && <CircularProgress />}
                </MessagesContainer>
                <InputContainer>
                    {currentChat === null ? (
                        <p className={"text-center"}>{t('choose_or_create_chat')}</p>
                    ) : (
                        <>
                            <Button onClick={() => setInputType(inputType === 'text' ? 'image' : 'text')} sx={{ minWidth: 0, marginRight: 1 }}>
                                {inputType === 'text' ? <FaImage size={25} /> : <FaKeyboard size={25} />}
                            </Button>
                            <CustomInput
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                placeholder={inputType === 'text' ? t('send_message') : t('send_image')}
                                disabled={sendingMessage}
                            />
                            <div className={"h-100 d-flex align-items-center p-1"} onClick={handleSendMessage} disabled={sendingMessage}>
                                {sendingMessage ? <CircularProgress size={24} /> : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                         fill="currentColor" className="bi bi-arrow-up-circle-fill"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                                    </svg>
                                )}
                            </div>
                        </>
                    )}
                </InputContainer>
            </ChatContainer>
            <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                <DialogTitle>{t('edit_chat_name')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('new_chat_name')}
                        fullWidth
                        value={newChatName}
                        onChange={(e) => setNewChatName(e.target.value)}
                        style={{ color: "var(--tg-theme-text-color)" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialogOpen(false)} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleEditChatName} color="primary">
                        {t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </AppContainer>
    );
};

export default App;
