import React from 'react';
import { Box, Typography, Button, Divider, List, ListItem, ListItemText, ListItemIcon, Grid } from '@mui/material';
import VerificationIcon from '@mui/icons-material/CheckCircle';
import BrandingIcon from '@mui/icons-material/BrandingWatermark';
import CategoriesIcon from '@mui/icons-material/Category';
import ProductsIcon from '@mui/icons-material/Inventory';
import CouponsIcon from '@mui/icons-material/ConfirmationNumber';
import AnalyticsIcon from '@mui/icons-material/Timeline';
import MailingIcon from '@mui/icons-material/Send';
import PaymentSystemsIcon from '@mui/icons-material/Payment';
import OrderSettingsIcon from '@mui/icons-material/Settings';
import BotSettingsIcon from '@mui/icons-material/Android';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddedBalance from './../Comps/Control/Balance';

import SelectLanguage from './../Comps/Control/SelectLanguage';
import WorkingConditions from './../Assets/Svg/working_conditions';
import HelpIcon from './../Assets/Svg/help_icon';
import ShopIcon from './../Assets/Svg/shop_icon';
import RefsIcon from './../Assets/Svg/refs_icon';
import DemoShopPng from './../Assets/Pngs/demo_shop.png';
import EyeGif from './../Assets/Pngs/eye.gif';
import WebAppzLogo from './../Assets/Pngs/webappz_logo.jpg';
import HeaderLogo from './../Assets/Pngs/header_logo.png';
import OurWebsitePng from './../Assets/Pngs/our_website.png';

import MyShops from './../Components/Blocks/Shops';
import MyRefs from './../Components/Blocks/Refs';

import { useTranslation } from 'react-i18next';

const IOSStyledPage = ({ user, refs, shops, setOpenModalShops, openModalShops }) => {

    const {t} = useTranslation();

    const [tgLink, setTgLink] = React.useState(user.language_code === 'ru' ? 'webappz' : 'webappzorg');
    const [demoTelegramLink, setDemoTelegramLink] = React.useState(user.language_code === 'ru' ? 'https://t.me/webappzbot/demo' : 'https://t.me/webappzbot/demoen');

    React.useEffect(() => {
        setTgLink(t('further') === 'Далее' ? 'webappz' : 'webappzorg');
        setDemoTelegramLink(t('further') === 'Далее' ? 'https://t.me/webappzbot/demo' : 'https://t.me/webappzbot/demoen');
    }, [t]);

    const [openShop, setOpenShop] = React.useState(false);

    return (
        <Box p={0}>
            <Box className="webappzGradient" sx={{ color: '#fff', padding: "20px", height: "120px" }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="h5" sx={{color: "#fff"}}>{user.first_name}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', padding: "10px", marginBottom: "6px" }}>
                            <svg width="25" height="25" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#0098EA"/>
                                <path d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z" fill="white"/>
                            </svg>
                            <Typography variant="h6" sx={{ fontSize: 16, marginLeft: "5px", marginTop: "4px", fontWeight: 'bold', color: "#fff" }}>
                                {parseFloat(user.balance).toFixed(2)} TON
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AddedBalance userData={user} />
                            {
                                user.balance >= 1 ? (
                                    <div style={{
                                        fontSize: 11,
                                        padding: "8 4",
                                        color: "#fff",
                                        width: "max-content",
                                        marginLeft: "15px"
                                    }}
                                    onClick={() => window.location.href = `https://t.me/appflap?text=${t('hello_withdraw_money')}`}>
                                        <div className="d-flex justify-content-center" style={{width: "100%"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28"><path fill="#fff" fillRule="evenodd" d="M14 3C7.925 3 3 7.925 3 14s4.925 11 11 11 11-4.925 11-11S20.075 3 14 3m4.53 9.47-4-4a.75.75 0 0 0-1.06 0l-4 4a.75.75 0 1 0 1.06 1.06l2.72-2.72V19a.75.75 0 0 0 1.5 0v-8.19l2.72 2.72a.75.75 0 1 0 1.06-1.06" clipRule="evenodd"></path></svg>
                                        </div>
                                        <b style={{color: "#fff"}}>{t('withdraw_money')}</b>
                                    </div>
                                ) : (
                                    <div style={{
                                        fontSize: 11,
                                        padding: "8 4",
                                        color: "#fff",
                                        width: "max-content",
                                        marginLeft: "15px"
                                    }} onClick={() => window.Telegram.WebApp.showAlert(t('min_one_ton'))}>
                                        <div className="d-flex justify-content-center" style={{width: "100%"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28"><path fill="#fff" fillRule="evenodd" d="M14 3C7.925 3 3 7.925 3 14s4.925 11 11 11 11-4.925 11-11S20.075 3 14 3m4.53 9.47-4-4a.75.75 0 0 0-1.06 0l-4 4a.75.75 0 1 0 1.06 1.06l2.72-2.72V19a.75.75 0 0 0 1.5 0v-8.19l2.72 2.72a.75.75 0 1 0 1.06-1.06" clipRule="evenodd"></path></svg>
                                        </div>
                                        <b style={{color: "#fff"}}>{t('withdraw_money')}</b>
                                    </div>
                                )
                            }
                        </Box>
                    </Grid>

                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={HeaderLogo} width="150px" />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ backgroundColor: 'var(--tg-theme-secondary-bg-color)', minHeight: 'max-content', padding: '8px' }}>

                <SelectLanguage user={user} />

                <Box sx={{ backgroundColor: 'var(--tg-theme-bg-color)', borderRadius: '12px', marginTop: '16px' }}>
                    <MyShops setOpenModalShops={setOpenModalShops} openModalShops={openModalShops} userData={user} shops={shops} openShop={openShop} setOpenShop={setOpenShop} />

                    <Divider sx={{ marginLeft: '44px' }} />

                    <MyRefs firstLevel={user.firstLevelRefs} secondLevel={user.secondLevelRefs} />

                    <Divider sx={{ marginLeft: '44px' }} />

                    <ListItem disableGutters sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        padding: '8px 0',
                        paddingLeft: '12px',
                        paddingRight: "12px",
                        borderRadius: '12px'
                    }} onClick={() => window.Telegram.WebApp.openTelegramLink(demoTelegramLink)}>
                        <img style={{ marginRight: '0px', fontSize: '24px' }} src={DemoShopPng} width={24} height={24} />
                        <div className="d-flex align-items-center" style={{ width: "100%", height: "100%", marginLeft: "8px", marginTop: "2px" }}>
                            {t('demo_shop')}
                        </div>
                        <img src={EyeGif} alt="Eye Gif" width="30" height="30" />
                    </ListItem>
                </Box>

                <Box sx={{ backgroundColor: 'var(--tg-theme-bg-color)', borderRadius: '12px', marginTop: '16px' }}>
                    <ListItem onClick={() => window.Telegram.WebApp.openLink('https://webappz.org/')} disableGutters sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        padding: '8px 0',
                        paddingLeft: '12px',
                        paddingRight: "12px",
                        borderRadius: '12px'
                    }}>
                        <img style={{ marginRight: '0px', fontSize: '24px' }} src={OurWebsitePng} width={24} height={24} />
                        <div className="d-flex align-items-center" style={{ width: "100%", height: "100%", marginLeft: "8px", marginTop: "2px" }}>
                            {t('out_website')}
                        </div>
                        <ChevronRightIcon sx={{ fill: "var(--tg-theme-subtitle-text-color)" }} />
                    </ListItem>
                </Box>

                <Box sx={{ backgroundColor: 'var(--tg-theme-bg-color)', borderRadius: '12px', marginTop: '16px' }}>
                    <ListItem disableGutters sx={{
                        display: 'flex', 
                        alignItems: 'center',
                        padding: '8px 0',
                        paddingLeft: '12px',
                        paddingRight: "12px",
                        borderRadius: '12px'
                    }} onClick={() => {window.Telegram.WebApp.openLink(t('working_conditions_url'), { try_instant_view: true })}}>
                        <WorkingConditions sx={{ marginRight: '16px', fontSize: '24px' }} />
                        <div className="d-flex align-items-center" style={{ width: "100%", height: "100%", marginLeft: "8px", marginTop: "2px" }}>
                            {t('working_conditions')}
                        </div>
                        <ChevronRightIcon sx={{ fill: "var(--tg-theme-subtitle-text-color)" }} />
                    </ListItem>

                    <Divider sx={{ marginLeft: '44px' }} />

                    <ListItem disableGutters sx={{
                        display: 'flex', 
                        alignItems: 'center',
                        padding: '8px 0',
                        paddingLeft: '12px',
                        paddingRight: "12px",
                        borderRadius: '12px'
                    }} onClick={() => window.location.href = 'https://t.me/appflap'}>
                        <HelpIcon sx={{ marginRight: '16px', fontSize: '24px' }} />
                        <div className="d-flex align-items-center" style={{ width: "100%", height: "100%", marginLeft: "8px", marginTop: "2px" }}>
                            {t('help')}
                        </div>
                        <ChevronRightIcon sx={{ fill: "var(--tg-theme-subtitle-text-color)" }} />
                    </ListItem>
                </Box>
                <div style={{width: "100%", marginTop: "15px", color: "var(--tg-theme-subtitle-text-color)"}} className="d-flex justify-content-center">
                    Powered by&nbsp;<a style={{color: "var(--tg-theme-subtitle-text-color)"}} href={`https://t.me/${tgLink}`}>@{tgLink}</a>
                </div>
                <div style={{width: "100%", color: "var(--tg-theme-subtitle-text-color)", marginTop: "5px"}} className="d-flex justify-content-center">
                    &copy; 2023-{new Date().getFullYear()}
                </div>
            </Box>
        </Box>
    );
};

export default IOSStyledPage;